@import '../../ui/colors.scss';
@import '../../ui/constants.scss';
@import '../../ui/media.scss';

.link {
    display: inline-block;
    padding: 0 8px;
    background-color: $yellow;
    font-weight: bold;
    color: $my-black;
    text-decoration: none;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: $extra-small;

    background-color: $yellow;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.8);
    will-change: background-color, box-shadow;
    transition: background-color $timer-standard ease,
        box-shadow $timer-standard ease;

    &:active,
    &:focus,
    &:hover {
        background-color: lighten($yellow, 25%);
        box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.8);
    }
}
