@import '../../ui/colors.scss';
@import '../../ui/constants.scss';
@import '../../ui/media.scss';

$dot-width: 8px;

.skills {
    dt,
    dd {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        margin: 0 0 0 0;
    }

    ul {
        list-style: none;
        text-align: right;
    }

    li {
        display: inline-block;
        vertical-align: top;
        width: $dot-width;
        height: $dot-width;
        border-radius: 50%;
        background-color: $indicator-grey;
        position: relative;
        top: 8px;
        margin: 0 0 0 6px;

        &.active {
            background-color: $my-black;
        }
    }

    .love {
        text-align: right;
        text-align: center;
        font-weight: bold;
    }

    // dt {
    //     width: $header-width;
    // }
    //
    // dd {
    //     width: calc(100% - $header-width);
    // }
}
