@import '../colors.scss';
@import '../constants.scss';

.image {
    width: 100%;
    background-color: $light-grey;
    position: relative;
    margin: 0;

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        will-change: opacity;
        transition: opacity $timer-long ease;
    }

    img.loaded {
        opacity: 1;
    }
}
