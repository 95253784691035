@import '../../ui/colors.scss';
@import '../../ui/constants.scss';


button {
    text-align: center;
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    z-index: 10;
    cursor: pointer;
    pointer-events: all;
    background-color: $yellow;
    border: none;
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.8);
    outline: none;
    will-change: background-color, box-shadow;
    transition: background-color $timer-standard ease,
        box-shadow $timer-standard ease;

    &:active,
    &:focus,
    &:hover {
        background-color: lighten($yellow, 25%);
        box-shadow: 0 0 20px -2px rgba(0, 0, 0, 0.6);
    }

    &.disabled {
        pointer-events: none;
        background-color: $light-grey;
    }

    svg {
        fill: $black;
    }

    .icon-send {
        margin: 0 0 0 4px;
    }

    .icon-camera {
        padding: 4px 0 0 0;
    }
}
