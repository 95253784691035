@import '../colors.scss';

svg {
    fill: $my-black;
}

.icon-wrapper {
    display: inline-block;
    width: 34px;
    height: 34px;

    svg {
        display: inline-block;
        width: 100%;
        height: 100%;
        stroke-width: 1px;
    }
}

.icon-arrow-up {
    width: 40px;
    height: 40px;
}
