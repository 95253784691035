@import "../../ui/colors.scss";
@import "../../ui/constants.scss";
@import "../../ui/media.scss";

.me-image {
  img {
    will-change: filter;
    transition: filter 500ms ease;
    filter: brightness(170%) grayscale(100%);
  }

  &.faded-item.active img {
    filter: brightness(100%) grayscale(0%);
  }
}

.info-wrapper {
  padding: 0;
  @include tablet {
    padding: 0 16px;
  }

  iframe {
    width: 100%;
    height: 80px;
  }

  .info-name {
    margin: 14px 0 0 0;
  }

  .faded-item {
    opacity: 1;
    will-change: opacity;
    transition: opacity $timer-standard ease;
  }

  .left-col {
    padding: 0 16px;

    @include tablet {
      display: inline-block;
      vertical-align: top;
      width: 360px;
      padding: 0 20px 0 0;
    }
  }

  .right-col {
    padding: 0 16px;

    @include tablet {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 360px);
      padding: 0 0 0 20px;
    }
  }

  .bio-wrapper {
    .faded-item {
      padding: 0 16px;

      @include tablet {
        padding: 40px 16px;
      }
    }

    .bio {
      max-width: 600px;
      text-align: justify;
      margin: 0 auto;
    }

    p {
      margin: 24px 0;
    }
  }

  .quotes-wrapper {
    i {
      display: block;
      padding: 0 20px;
      font-size: 20px;
      text-align: center;
      line-height: 1.6;

      @include tablet {
        min-height: 90px;
        padding: 0 60px;
      }
    }
  }
}

@include desktop {
  .info-container {
    &.is-hovered {
      .faded-item {
        opacity: 0.15;
      }
    }

    .active {
      &.faded-item {
        opacity: 1;
      }
    }
  }
}
