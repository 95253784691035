.wrapper {
    width: 200%;
    transform: translateX(-50%);

    .frame-one,
    .frame-two {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        opacity: 1;
        will-change: opacity;
        transition: opacity 800ms ease 20ms;
    }
}

.initialized .wrapper {
    will-change: transform;
    transition: transform 500ms ease 10ms;
}

.app.bilder {
    .wrapper {
        transform: translateX(0%);
    }

    .frame-one {
        opacity: 0;
    }

    .frame-two {
        opacity: 0;
    }

    &.page-active .frame-one {
        opacity: 1;
    }
}

.app.info {
    .wrapper {
        transform: translateX(-50%);
    }
    .frame-one {
        opacity: 0;
    }
    .frame-two {
        opacity: 0;
    }
    &.page-active .frame-two {
        opacity: 1;
    }
}
