@import './constants.scss';

@mixin landscape {
    @media (min-width: #{$landscape}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}

@mixin wide {
    @media (min-width: #{$wide}) {
        @content;
    }
}

@mixin super {
    @media (min-width: #{$super}) {
        @content;
    }
}
