@import '../../ui/colors.scss';
@import '../../ui/constants.scss';
@import '../../ui/media.scss';

.section {
    padding: 0 0 40px 0;

    .section-header {
        height: 38px;
        line-height: 38px;
        position: relative;
        text-align: center;
        margin: 0 0 16px 0;

        h2 {
            display: inline-block;
            padding: 5px 10px 4px 10px;
            letter-spacing: -0.02em;
            background-color: $my-black;
            color: $white;
            margin: 0;
            z-index: 5;
            position: relative;
            font-size: 12px;
            line-height: 16px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            border-radius: 4px;
        }

        .section-header-line-through {
            height: 2px;
            background-color: $my-black;
            width: 100%;
            position: absolute;
            top: 19px;
            left: 0;
            z-index: 3;
        }
    }
}
