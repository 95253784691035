$timer-super: 2000ms;
$timer-standard: 500ms;
$timer-short: 300ms;
$timer-mini: 150ms;
$timer-long: 1000ms;

$micro: 10px;
$extra-small: 12px;
$small: 14px;
$regular: 16px;
$large: 18px;
$extra-large: 20px;
$super-large: 22px;
$line-height-small: 1.4;
$line-height-standard: 1.8;
$page-header-header-line-height: 1.6;
$page-header-header-line-height-tablet: 1.4;

$landscape: 480px;
$tablet: 768px;
$desktop: 1024px;
$wide: 1600px;
$super: 2048px;
