@import '../../ui/media.scss';
@import '../../ui/colors.scss';

.quiz {

    padding: 20px 16px;

    @include landscape {
        padding: 20px 25px;
    }

    h3 {
        font-size: 20px;
        vertical-align: top;
        line-height: 40px;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        font-family: 'Montserrat', sans-serif;

        span {
            display: inline-block;
            vertical-align: top;
            width: 30%;
        }

        .subject {
            width: 70%;
            font-size: $regular;
            text-align: right;
        }
    }

}

.quiz-top {

    text-align: center;
    dd, dt {
        display: inline-block;
        vertical-align: top;
        padding: 6px 0;
    }

    dt {
        font-weight: normal;
        width: 30%;
    }

    dd {
        font-weight: bold;
        width: 70%;
    }

    i {
        color: $print;
        font-size: $extra-small;
        display: block;
        position: relative;
        top: -3px;
    }
}
