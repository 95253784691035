@import "../../ui/colors.scss";
@import "../../ui/constants.scss";
@import "../../ui/media.scss";

.works-wrapper {
  .section {
    padding: 0;
  }

  #tactel {
    .work-wrapper {
      padding: 10px 0 40px 0;
    }
  }

  .section-header {
    opacity: 1;
    will-change: opacity;
    transition: opacity $timer-standard ease;
  }
}

.is-hovered {
  .works-wrapper {
    .section-header {
      opacity: 0.15;
    }
  }
}

.is-work-hovered {
  .works-wrapper {
    .section-header {
      opacity: 1;
    }
  }
}

.works {
  .work-wrapper {
    padding: 10px 0;
    border-bottom: 1px solid $indicator-grey;
  }

  li:last-child {
    .work-wrapper {
      border-bottom: none;
    }
  }

  .year,
  .assignment {
    display: inline-block;
    vertical-align: top;
  }

  .year {
    width: 50px;
    position: relative;

    @include tablet {
      text-align: center;
      width: 90px;
    }

    .btn {
      position: absolute;
      top: 36px;
      left: 0;

      @include tablet {
        left: 30px;
      }
    }
  }

  .btn {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    .icon-wrapper {
      width: 20px;
      height: 20px;
    }
  }

  .assignment {
    width: calc(100% - 50px);

    @include tablet {
      width: calc(100% - 90px);
    }
  }

  &.lysa {
    #lysa .section {
      opacity: 1;
    }
  }

  .work-item {
    opacity: 1;
    will-change: opacity;
    transition: opacity $timer-standard ease;
  }
}

@include desktop {
  .info-container.is-hovered {
    .work-item {
      opacity: 0.15;
    }
  }
  .is-work-hovered .average {
    #average {
      opacity: 1;
    }
  }
  .is-work-hovered .lysa {
    #lysa {
      opacity: 1;
    }
  }
  .is-work-hovered .viaplay {
    #viaplay {
      opacity: 1;
    }
  }
  .is-work-hovered .dn {
    #dn {
      opacity: 1;
    }
  }
  .is-work-hovered .avanza {
    #avanza {
      opacity: 1;
    }
  }
  .is-work-hovered .tactel {
    #tactel {
      opacity: 1;
    }
  }
}
