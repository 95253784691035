@import './ui/colors.scss';
@import './ui/media.scss';
@import './ui/constants.scss';

* {
    box-sizing: border-box;
}

dl,
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

dd,
dt {
    padding: 0;
    margin: 0;
}

body {
    font-size: $small;
    color: $my-black;
    font-weight: 400;
    line-height: 1.8;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 40px 0 0 0;
    background-color: $white;
    min-width: 100vw;
    min-height: 100vh;
    position: relative;
}

.page-header {
    position: relative;
    margin: 0;
    padding: 0;

    h1 {
        font-size: 60px;
        line-height: 60px;
        font-family: 'Playfair Display', sans-serif;
        font-weight: 900;
        position: absolute;
        width: 100%;
        margin: 0;
        left: 0;
        top: -72px;
        text-align: center;
        letter-spacing: -0.04em;
        pointer-events: none;

        transition: transform $timer-short ease 100ms;
        transform: translateY(-40px);

        @include desktop {
            font-size: 140px;
            line-height: 140px;
            top: -117px;
            transform: translateY(-80px);
        }
    }
}

.quiz1 {
    .page-header {

        h1 {
            top: -40px;
        }
    }    
}

.menu-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    perspective: 1000px;
    transform-style: preserve-3d;
    z-index: 200;
    padding: 16px;

    @include tablet {
        padding: 16px 20px;
    }

    .menu {
        margin: 0 auto;
        max-width: $desktop;
        position: relative;
    }

    .menu-scroll,
    .menu-camera,
    .menu-person {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
    }

    .menu-camera,
    .menu-person {
        transition: transform $timer-standard ease-in-out;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transform: rotateY(0deg);
    }

    .menu-scroll {
        top: 60px;
        transition: transform $timer-standard ease-in-out, opacity $timer-short ease-in-out;
        transform: scale(0);
        opacity: 0;

        button {
            padding: 5px;
        }
    }

    .menu-person {
        transform: rotateY(180deg);
        z-index: 50;
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
    }

}

.app.has-offset {
    .menu-scroll {
        transform: scale(1);
        opacity: 1;
    }
}


.app.bilder {
    .menu-camera {
        transform: rotateY(180deg);
    }
    .menu-person {
        transform: rotateY(0deg);
    }
}

.page-body {
    padding: 20px 0;
    margin: 0 auto;
    max-width: $desktop;
    overflow: hidden;

    @include tablet {
        padding: 50px 0;
    }
}
.app {
    position: relative;
}

.curtain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $white;
    opacity: 1;
    will-change: opacity;
    transition: opacity 500ms ease;
    z-index: 310;
}

.initialized {
    .curtain {
        opacity: 0;
        pointer-events: none;
    }

    .page-header {
        h1 {
            transform: translateY(0);
        }
    }
}
