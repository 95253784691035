@import '../../ui/colors.scss';
@import '../../ui/constants.scss';
@import '../../ui/media.scss';

.images-container {

    padding: 16px;
    max-width: 800px;
    margin: 0 auto;

    .image-wrapper {
        margin: 0 0 40px 0;
    }

    img {
        box-shadow: 0 0 20px -6px #333;
    }

    .image-note {
        margin: 4px 0 0 0;
        font-size: $extra-small;

        @include tablet {
            margin: 6px 0 0 0;
        }
    }

    @include tablet {
        .split-image {
            & > div {
                display: inline-block;
                width: 50%;
                vertical-align: top;
                padding: 0 12px 0 0;

                &:last-child {
                    padding: 0 0 0 12px;
                }
            }
        }
        .centered-image {
            text-align: center;

            .image-wrapper {
                display: inline-block;
                width: 50%;
                vertical-align: top;
            }

        }
    }
}
