@import '../../ui/media.scss';
@import '../../ui/colors.scss';

$color: #e5e5e5;
$dark: darken($color, 12%);
$tone: lighten($color, 8%);

.tips-page {

    position: relative;

    .post-comments {
        max-width: 600px;
        margin: 60px auto 30px auto;
    }

    .post-list {
        border-bottom: 1px solid $color;
    }

    .post-comments {
        li {

            border-top: 1px solid $color;
            border-left: 4px solid lighten(#f44336, 20%);

            @include tablet {
                border: 1px solid $color;
                border-left: 4px solid lighten(#f44336, 20%);
                border-bottom: 0;
            }

            & > div {
                padding: 20px 16px;
            }

            .post-header {
                padding: 6px 16px;
                border-bottom: 1px solid $color;
                background-color: $tone;
            }

            &:nth-child(even) {
                border-left: 4px solid lighten(#459fff, 10%);

                .post-header {
                    background-color: $tone;
                }
            }

            .post-name,
            .post-date {
                vertical-align: top;
                width: 50%;
                display: inline-block;
            }

            .post-name {
                font-weight: 700;
            }

            .post-date {
                text-align: right;

                .time {
                    font-weight: 700;
                }
            }
        }
    }

    form {
        padding: 0 16px;

        @include tablet {
            padding: 0;
        }
    }

    textarea,
    input {
        padding: 10px;
        border: 1px solid $color;
        display: block;
        width: 100%;
        outline: none;
    }

    textarea {
        resize: none;
        margin: 10px 0;
    }

    .relative {
        position: relative;
    }

    a {
        color: #0672e5;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
    }

    .show-table-link {

        transition: opacity 500ms ease;
        opacity: 1;
        padding: 10px 0 0 16px;

        &.toggled {
            opacity: 0;
        }
    }
}


.tips-table {

    max-width: 320px;
    margin: 0 auto 0 auto;
    transition: max-height 500ms ease, opacity 300ms ease;
    max-height: 0;
    opacity: 0;
    overflow: hidden;

    .table-header {
        padding: 40px 0 0 0;
        background-color: #fff;
        z-index: 20;
    }

    &.toggled {
        max-height: 3500px;
        opacity: 1;
    }



    &.fixed {
        .table-header {
            position: fixed;
            top: 0;
            padding: 40px 0 0 0;

            @include tablet {
                padding: 10px 0 0 0;
            }
        }
    }

    @include tablet {
        max-width: 600px;
    }

    li {
        width: 140px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        padding: 20px 6px 0 6px;
        border-bottom: 1px solid #f7f7f7;
        height: 50px;
        line-height: 20px;


        @include tablet {
            width: 180px;
            padding: 20px 0 0 0;
        }
    }

    .cell-game {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: clip;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 20px;
    }

    .cell-status,
    .cell-player {
        width: 30px;

        @include tablet {
            width: 70px;
        }
    }

    .cell-status {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    .cell-player {

        @include tablet {
            text-align: center;
        }

        span {
            transform: translateX(20px) rotateZ(-90deg);
            display: inline-block;
            transform-origin: left bottom;

            @include tablet {
                transform: translateX(36px) rotateZ(-50deg);
                transform: translateX(0) rotateZ(0);
            }
        }
    }

    .cell-date {
        position: absolute;
        top: 2px;
        left: 6px;
        font-size: 11px;

        @include tablet {
            left: 0;
        }
    }

    .cell-game {
        font-weight: 700;
    }

    .cell-hit {
        background-color: #bbecc1;
        font-weight: 700;
        border-bottom: 1px solid transparent;
    }

    .cell-miss {
        background-color: #f7cac7;
        font-weight: 700;
        border-bottom: 1px solid transparent;

    }

    .goaler {

        padding: 60px 6px 20px 6px;
        height: auto;

        @include tablet {
            padding: 20px 0 0 0;
            height: 50px;
        }

        span {
            transform: translateX(18px) rotateZ(-90deg);
            display: inline-block;
            transform-origin: left bottom;

            @include tablet {
                transform: translateX(0) rotateZ(0);
            }
        }
    }
}
