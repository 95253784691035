$black: #000;
$white: #fff;

$my-black: #47525d;
// $grey: #d9dee4;
$indicator-grey: #d0dae4;
$pale-grey: #e9f0f6;
$light-grey: #f2f5f8;
$dark-grey: #8a919d;
$grey: #bdc5cd;
$print: #6b6e71;
$dark-grey: #8a919d;

$red: #f4000e;
$yellow: #ffed00;
