@import '../../ui/colors.scss';

.question {

    position: relative;
    line-height: 1.5;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
    border-bottom: 1px solid $grey;

    &:last-child {
        border-bottom: 0;
        margin: 0 0 60px 0;
    }

    .quiz-points,
    h4 {
        position: absolute;
        top: 0;
        left: 0;
    }

    .quiz-points {
        top: 20px;
        max-width: 50px;
        padding: 0 6px 0 0;
        font-size: 12px;
    }

    p {
        padding: 0 0 14px 50px;
        margin: 0;
        line-height: 1.5;
    }

    .quiz-answer {
        position: relative;
        padding: 0 0 0 50px;
        font-style: italic;
        font-size: 13px;

        span:first-child {
            position: absolute;
            top: 0;
            left: 0;
            font-style: normal;
        }
    }

    .quiz-answer-link {
        margin: 10px 0;

        a {
            font-style: normal;
        }
    }
}
