@import './constants.scss';
@import './media.scss';

/* playfair-display-900 - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 900;
    src: local('Playfair Display Black'), local('PlayfairDisplay-Black'),
        url('./fonts/playfair-display-v13-latin-900.woff2') format('woff2'),
        url('./fonts/playfair-display-v13-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-900italic - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 900;
    src: local('Playfair Display Black Italic'),
        local('PlayfairDisplay-BlackItalic'),
        url('./fonts/playfair-display-v13-latin-900italic.woff2')
            format('woff2'),
        url('./fonts/playfair-display-v13-latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url('./fonts/montserrat-v12-latin-300.woff2') format('woff2'),
        url('./fonts/montserrat-v12-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('./fonts/montserrat-v12-latin-regular.woff2') format('woff2'),
        url('./fonts/montserrat-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
        url('./fonts/montserrat-v12-latin-300italic.woff2') format('woff2'),
        url('./fonts/montserrat-v12-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
        url('./fonts/montserrat-v12-latin-italic.woff2') format('woff2'),
        url('./fonts/montserrat-v12-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('./fonts/montserrat-v12-latin-700.woff2') format('woff2'),
        url('./fonts/montserrat-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
        url('./fonts/montserrat-v12-latin-700italic.woff2') format('woff2'),
        url('./fonts/montserrat-v12-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

h1,
h2,
h3 {
    line-height: $page-header-header-line-height;
    font-family: 'Playfair Display', sans-serif;
    margin: 30px 0 14px 0;
    font-weight: 900;

    &:first-child {
        margin: 0 0 14px 0;
    }

    @include tablet {
        line-height: $page-header-header-line-height-tablet;
    }
}

h4,
h5 {
    margin: 30px 0 14px 0;
    font-weight: 900;

    &:first-child {
        margin: 0 0 14px 0;
    }

    // line-height: $page-header-header-line-height;
    // @include tablet {
    //     line-height: $page-header-header-line-height-tablet;
    // }
}

p {
    font-size: $small;
    line-height: $line-height-standard;
    margin: 14px 0;

    &:first-child {
        margin: 0 0 14px 0;
    }
}

strong {
    font-weight: 700;
}
